<div class="modal-contents">

  <!-- Modal Header -->
  <div class="modal-header" [ngClass]="!header ? 'no-header' : ''">
    <div class="row" *ngIf="header">
      <div class="col-12 text-center">
        <label><strong>{{header}}</strong></label>
      </div>
    </div>
  </div>

  <!-- Modal body -->
  <div class="modal-body">
    <div class="row justify-content-center confirm-title">
      <div class="col-12 text-center" *ngIf="!config.noIcon">
        <img [src]="iconImage" class="icon-popup">
      </div>
      <div class="col-12 text-center">
        <span>{{title}}</span>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="container-fluid col-md-12 footer-button">
      <div class="d-flex justify-content-between">
        <button class="btn btn-cancel" (click)="cancel()">
          {{buttonCancelLabel}}
        </button>
        <button class="btn btn-buy btn--green" type="button" (click)="argree()">
          {{buttonLabel}}
        </button>
      </div>
    </div>
  </div>
</div>