import {AfterViewInit, Component, OnDestroy, OnInit, Input, Output, EventEmitter} from "@angular/core";
import { EventManagerService } from '../../service/event-manager.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Constant } from '../../constant';
import { ShortcutService } from "../../service/shortcut.service";
/**
 * declare Jquery
 * */
declare let $: any;

@Component({
  selector: 'confirm-popup',
  templateUrl: "./confirm-popup.html",
  styleUrls: ["./confirm-popup.scss"]
})

export class ConfirmPopupComponent {
    @Input('properties') properties;
    @Input('header') header;
    @Input('title') title;
    @Input('buttonLabel') buttonLabel = 'Xác nhận';
    @Input('buttonCancelLabel') buttonCancelLabel = 'Đóng';
    @Input() isDismissAll: boolean;
    @Output() result: EventEmitter<any> = new EventEmitter();
    iconImage: any = './assets/images/icons/warning.png';
    Constant = Constant;
    func: any;
    cancelFunc: any;
    config: any = {};
    constructor(
        public eventManger: EventManagerService,
        public activeModal: NgbActiveModal,
        public ngModal: NgbModal
    ) {
    }
    ngOnInit() {
        if (this.properties) {
            this.setData(this.properties);
        }
    }
    ngAfterViewInit() {
    }
    ngOnDestroy() {
    }
    setData(data) {
        this.header = data.header;
        this.title = data.title;
        this.config = data.config || {};
        this.buttonLabel = data.buttonLabel || 'Xác nhận';
        this.buttonCancelLabel = data.buttonCancelLabel || 'Đóng';
        this.func = data.func;
        this.cancelFunc = data.cancelFunc;
        this.isDismissAll = data.isDismissAll;
    }
    argree() {
        this.dismiss();
        if (this.func) {
            this.func();
        } else this.result.emit(true);
    }
    cancel() {
        this.dismiss();
        if (this.cancelFunc) {
            this.cancelFunc();
        } else this.result.emit(false) 
    }
    dismiss() {
        ShortcutService.removeFuncEsc();
        if (!this.isDismissAll) {
            this.activeModal.dismiss();
        } else {
            this.ngModal.dismissAll();
        }
        this.result.emit(true);
    }
}
