<div class="dropdown" [ngClass]="disabled ? 'disabled' : ''" [style.background-color]="backgroundColor">
  <!-- <input class="form-control" id="action{{id}}" type="text" placeholder="{{placeholder}}" [(ngModel)]="name" 
    (click)="open()" (ngModelChange)="changeValue()"> -->
  <input class="form-control" id="action{{id}}" (click)="open()" type="text" placeholder="{{placeholder}}" [(ngModel)]="textSearch" (keyup)="filter()">
  <div id="{{id}}" class="dropdown-content" [ngClass]="openDropdownlist ? 'show' : ''">
    <!-- <input class="form-control input-search" type="text" placeholder="Search.." [(ngModel)]="textSearch" (keyup)="filter()"> -->
    <div class="list-item">
      <a class="record" *ngFor="let item of list" [ngClass]="item.isHide ? 'hide' : ''" (click)="chooseValue(item)">
        <img *ngIf="item.icon" [src]="item.icon" height="25" style="margin-right: 10px;">
        <span >{{item[field]}}</span>
      </a>
    </div>
  </div>
</div>