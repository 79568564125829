import {Injectable, OnInit} from '@angular/core';
import {Constant} from "../constant";
import { HttpClient } from '@angular/common/http';
import { ROUTE_INVESTOR} from '../route.url';
import { UserProfile } from '../models/users.model';
import { AppQueries, Dispatcher, StoreInfoUser } from 'src/app/state';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService implements OnInit  {
  public user: UserProfile = new UserProfile();
  public authenticationState = new Subject<any>();
  constructor(
    private appQueries: AppQueries,
    private http: HttpClient,
    private dispatcher: Dispatcher
  ) {
  }
  ngOnInit() {
    this.appQueries.userProfile$
    .subscribe((userProfile) => {
      this.user = userProfile;
      this.authenticationState.next(new UserProfile(userProfile));
    })
  }
  clear() {
    this.user.id = null;
  }
  getProfile() {
    return this.user;
  }
  subProfile() {
    return new Promise((resolve, reject) => {
      let interval = setInterval(() => {
        if (this.user.id) {
          clearInterval(interval);
          resolve(this.getProfile());
        }
      }, 500)
    })
  }
  getProfileAPI(isRefresh?, isFirst?) {
    return new Promise<boolean>((resolve, reject) => {
      if (this.user.code && !isRefresh) {
        resolve(true)
      } else {
        this.http.get(environment.urlBackEnd +  Constant.api_user_profile)
          .subscribe((res: any) => {
            const profile =  res.data;
            this.user = new UserProfile(profile);
            this.dispatcher.fire(new StoreInfoUser(profile));
            resolve(res.data);
          }, (error) => {
            reject(error)
          })
      }
    })
  }
  checkAuthorUrl(route: any) {
    // let url = route._routerState.url;
    // let listChildren= [];
    // if (this.user.userType === Constant.INVESTOR) {
    //   listChildren = ROUTE_INVESTOR || [];
    // } 
    // return listChildren.some(children => children.path.includes(url) || url.includes(children.path) );
    return true;
  }
  firstUrl() {
    let route: any;
    return route  ? route.path : '';
  }
  checkFullInformation(user?) {
    user = user || this.user;
    if (!this.checkInformationBank(user)) {
      return false;
    }
    if (!this.checkInformationPersonal(user)) {
      return false;
    }
    return true;
  }
  checkInformationPersonal(user?) {
    let information = user ? user : this;
    if (!information.address1 || (information.isPersonalInvestor &&  !information.address2) || !information.province.id  || !information.passportDateOfIssue 
      || (information.passportDateOfIssue && information.passportDateOfIssue.length < 8) || !information.passportPlaceOfIssue) {
        return false;
      }
    if (information.isPersonalInvestor) {
      if ((information.birthDate && information.birthDate.length < 8)  || !information.birthDate || !information.passport || (information.gender != 1 && information.gender != 0)) {
        return false;
      }
    } else {
      if (!information.taxCode || !information.authorizedPerson) {
        return false;
      } 
    }
    return true;
  }
  checkInformationBank(user?) {
    let information = user ? user : this;
    if (information.bankAccounts && information.bankAccounts.length > 0) {
      return true;
    }
    return false;
  }
}
