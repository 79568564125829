<div class="dropdown" [ngClass]="disabled ? 'disabled' : ''" [style.background-color]="backgroundColor">
  <div class="form-control dropdown-display-value" id="action{{id}}" (click)="open()" [style.min-width]="minWidth">
    <span>{{name || title}}</span>
  </div>
  <div id="{{id}}" class="dropdown-content" [ngClass]="openDropdownlist ? 'show'  : ''">
    <input class="form-control" type="text" placeholder="Search.." [(ngModel)]="textSearch" (keyup)="filter()">
    <div class="list-item" *ngIf="groups.length === 0; else haveGroups">
      <a class="d-flex" (click)="chooseValue(defaultItem)" *ngIf="!isNoAll">
        <span>Chọn tất cả</span>
      </a>
      <a class="record" *ngFor="let item of list" [ngClass]="item.isHide ? 'hide'  : ''" (click)="chooseValue(item)">
        <img *ngIf="item.icon" [src]="item.icon" height="25" style="margin-right: 10px;">
        <span>{{item[field]}}</span>
        <span *ngIf="item.isCheck">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"  viewBox="0 0 16.68 11.91">
            <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                    <path class="cls-1"
                        d="M6.18,11.78A.59.59,0,0,0,7,11.71L16.53,1A.6.6,0,1,0,15.64.2L6.48,10.5,1,6.09A.6.6,0,1,0,.22,7Zm0,0" />
                </g>
            </g>
          </svg>
        </span>
      </a>
    </div>
    <ng-template #haveGroups>
      <div class="list-item">
        <div class="d-flex flex-column" *ngFor="let group of groups">
          <span class="sub-title" (click)="chooseGroup(group)">{{group.option?.title}}</span>
          <div class="d-flex flex-column" style="margin-left: 15px;">
            <a class="record" *ngFor="let item of group.list" (click)="chooseValue(item)"
              [ngClass]="item.isHide ? 'hide'  : ''">
              <img *ngIf="item.icon" [src]="item.icon" height="25" style="margin-right: 10px;">
              <span>{{item[field]}}</span>
              <span *ngIf="item.isCheck">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"  viewBox="0 0 16.68 11.91">
                  <g id="Layer_2" data-name="Layer 2">
                      <g id="Layer_1-2" data-name="Layer 1">
                          <path class="cls-1"
                              d="M6.18,11.78A.59.59,0,0,0,7,11.71L16.53,1A.6.6,0,1,0,15.64.2L6.48,10.5,1,6.09A.6.6,0,1,0,.22,7Zm0,0" />
                      </g>
                  </g>
                </svg>
              </span>
            </a>
          </div>
        </div>
      </div>
    </ng-template>

  </div>
</div>
<span id="backdrop" class="backdrop"></span>