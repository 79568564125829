import { Pipe, PipeTransform } from '@angular/core';
@Pipe({name: 'money'})
export class TransformMoneyPipe implements PipeTransform {
  transform(value: number, removeDecimal?:any) {
      if (removeDecimal) {
      }
      return this.parseMoney(value, removeDecimal);
  }
  parseMoney(number, removeDecimal?) {
    if (number) {
      number = this.decimalAdjust('round', number , -3);
      number+='';
      let negative = false;
      if (number[0] === '-') {
         negative = true;
         number = number.substring(1, number.length);
      }
      const array = number.split('.');
      number= array[0]; let index = 0, str = '';
      let decimal = array[1];
      //remove Decimal
      if (decimal && removeDecimal) {
        const decimalValue= '0.' + decimal;
        if (parseFloat(decimalValue) > 0.5) {
          number = parseInt(number) + 1;
        } else {
          number = parseInt(number);
        }
        number += '';
        decimal = '';
      }
      // end
      for (let i= number.length - 1; i >= 0; i--) {
        index++;
        str= number.charAt(i) + str;
        if (index % 3 === 0) {
          str = ',' + str;
          index = 0;
        }
      }
      if (str.charAt(0) === ',') {
        str = str.substring(1, str.length);
      }
      negative && (str = '-' + str)
      return decimal ? str + '.' + decimal : str;
    } 
    else if (number === 0 || number === '0') {
      return '0';
    } 
    else {
      return '_';
    }
  }
  decimalAdjust(type, value, exp) {
    if (exp < 0) {
      exp = exp * -1;
    }
    let valueMul = 1;
    for (let i=1; i <= exp; i++) {
      valueMul= valueMul * 10;
    }
    return Math[type](value * valueMul) / valueMul;
  }
}