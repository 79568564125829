import {AfterViewInit, Component, OnDestroy, OnInit, Input, Output, EventEmitter} from "@angular/core";
import { Constant } from "../../constant";
import { Subscription } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from "@angular/router";
/**
 * declare Jquery
 * */
declare let $: any;

@Component({
  selector: 'public-view-image',
  templateUrl: "./public-view-image.html",
  styleUrls: ['./public-view-image.scss'],
})

export class PublicViewImageComponent implements OnInit, AfterViewInit, OnDestroy  {
    photo: any = {};
    event: Subscription;
    Constant = Constant;
    rotatePhoto = 1;
    constructor(
        public activeModal: NgbActiveModal,
        public route: ActivatedRoute,
    ) {

    }
    ngOnInit() {
        const params = this.route.snapshot.queryParams || {};
        this.photo = {
            fileName: params.fileName,
            url: params.url
        }
    }
    ngAfterViewInit() {
        
    }
    ngOnDestroy() {
        this.event.unsubscribe();
    }
    dismiss() {
        this.activeModal.dismiss();
    }
    getRotate() {
        let cla = '';
        switch (this.rotatePhoto) {
            case 1:
                cla = 'rotate0';
            break;
            case 2:
                cla = 'rotate90';
            break;
            case 3:
                cla = 'rotate180';
            break;
            case 4:
                cla = 'rotate270';
            break;
        }
        return cla;
    }
    rotatePrevious() {
        if (this.rotatePhoto > 1) {
            this.rotatePhoto--;
        }
    }
    rotateNext() {
        if (this.rotatePhoto < 4) {
            this.rotatePhoto++;
        }
    }
    download() {
        window.open(this.photo.url, '_blank');
    }
}
