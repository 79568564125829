<!-- Show photo. -->
<div class="">
    <div class="modal-header">
        <div class="row">
            <div class="col-12 text-center">
                <label for="">PHOTO: {{photo.fileName}}</label>
            </div>
        </div>
    </div>
    <div class="">
        <div class="row">
            <div class="col-12 text-center ">
                <div class="view-photo">
                    <img [src]="photo.url" [ngClass]="getRotate()">
                </div>
                <div class="photo-control">
                    <a [ngClass]="rotatePhoto === 1 ? 'disabled-button' : ''" (click)="rotatePrevious()">
                        <img src="./assets/images/icon/rotate-left.svg" alt="" 
                            width="50" title="Quay trái">
                    </a>
                    <a [ngClass]="rotatePhoto === 4 ? 'disabled-button' : ''" (click)="rotateNext()">
                        <img src="./assets/images/icon/rotate-right.svg" alt="" 
                            width="50" title="Quay phải">
                    </a>
                    <a (click)="download()">
                        <img src="./assets/images/icon/download.svg" title="Tải về" width="50">
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>