import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  Input,
} from "@angular/core";
import { Constant } from "../../constant";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { EventManagerService } from "../../service/event-manager.service";
import { Subscription } from "rxjs";
import { DateUtil } from "../../utils/date.util";
/**
 * declare Jquery
 * */
declare let $: any;

@Component({
  selector: "filter-start-end-date",
  templateUrl: "./filter-start-end-date.html",
  styleUrls: ["./filter-start-end-date.scss"],
})
export class FilterStartEndDateComponent implements OnInit, OnDestroy {
  @Input("title") title: any;
  @Input("titleStart") titleStart: any;
  @Input("titleEnd") titleEnd: any;
  @Input("startDate") startDate: any;
  @Input("endDate") endDate: any;
  @Input("isDefaultData") isDefaultData: boolean = true;

  formStartDate: FormGroup = this.formBuilder.group({
    myDate: [null, Validators.required],
  });
  formEndDate: FormGroup = this.formBuilder.group({
    myDate: [null, Validators.required],
  });
  minStartDate: string = "2024-01-01";
  maxStartDate: string = "2024-01-01";
  minEndDate: string = "2024-01-01";
  maxEndDate: string = "2024-01-01";
  Constant = Constant;
  subscriptions: Subscription[];
  constructor(
    private formBuilder: FormBuilder,
    public eventManager: EventManagerService
  ) {}
  ngOnInit() {
    const currentDate = new Date();
    this.maxEndDate = DateUtil.parseDate(currentDate, "yyyy-MM-dd");
    this.maxStartDate = DateUtil.parseDate(currentDate, "yyyy-MM-dd");
    this.subscriptions = [
      ...[
        this.eventManager.subscribe("reset-data-filter", (res) => {
          this.setDefault();
        }),
        this.formStartDate.get("myDate").valueChanges.subscribe((val) => {
          this.startDate = val
            ? DateUtil.parseDate(
                DateUtil.revertDate(val + ", 00:00:00", "yyyy-MM-dd, HH:mm:ss"),
                "yyyyMMdd, HH:mm:ss"
              )
            : null;
          this.eventManager.broadcast({
            name: "change-filter-start-end-date",
            content: this.emitData(),
          });
        }),
        this.formEndDate.get("myDate").valueChanges.subscribe((val) => {
          this.endDate = val
            ? DateUtil.parseDate(
                DateUtil.revertDate(val + ", 23:59:59", "yyyy-MM-dd, HH:mm:ss"),
                "yyyyMMdd, HH:mm:ss"
              )
            : null;
          this.eventManager.broadcast({
            name: "change-filter-start-end-date",
            content: this.emitData(),
          });
        }),
      ],
    ];
    this.setNull();
    //  set default date
    this.setDefault();
  }

  ngAfterViewInit(): void {
    const startDate = document.getElementById("startDate") as HTMLInputElement;
    const endDate = document.getElementById("endDate") as HTMLInputElement;

    startDate.addEventListener("input", function () {
      if (this.value) {
        this.style.color = "#202D41"; // Màu của văn bản khi đã chọn ngày
      } else {
        this.style.color = "transparent"; // Màu của văn bản khi chưa chọn ngày
      }
    });
    endDate.addEventListener("input", function () {
      if (this.value) {
        this.style.color = "#202D41"; // Màu của văn bản khi đã chọn ngày
      } else {
        this.style.color = "transparent"; // Màu của văn bản khi chưa chọn ngày
      }
    });
  }
  ngOnDestroy() {}
  setNull() {
    this.setDate(this.formStartDate, null);
    this.startDate = null;
    this.setDate(this.formEndDate, null);
    this.endDate = null;
  }
  setDefault() {
    if (this.isDefaultData) {
      let date = new Date();
      this.endDate = date.valueOf();
      this.setDate(this.formEndDate, date);
      this.startDate = new Date(
        date.getMonth() + 1 + "/" + 1 + "/" + date.getFullYear()
      ).valueOf();
      this.setDate(
        this.formStartDate,
        new Date(date.getMonth() + 1 + "/" + 1 + "/" + date.getFullYear())
      );
    } else {
      this.startDate = null;
      this.setDate(this.formStartDate, null);
      this.endDate = null;
      this.setDate(this.formEndDate, null);
    }
    const startDate = new Date(this.startDate);
  }
  setDate(formDate, date): void {
    // Set today date using the setValue function
    formDate.setValue({
      myDate: date ? DateUtil.parseDate(date, "yyyy-MM-dd") : null,
    });
  }
  checkValidDate() {
    const startDate = this.startDate
      ? DateUtil.revertDate(this.startDate, "yyyyMMdd, HH:mm:ss")
      : null;
    const endDate = this.endDate
      ? DateUtil.revertDate(this.endDate, "yyyyMMdd, HH:mm:ss")
      : null;

    if (startDate && startDate > endDate) {
      this.endDate = this.startDate
        ? DateUtil.parseDate(
            DateUtil.revertDate(this.startDate, "yyyyMMdd, HH:mm:ss"),
            "yyyyMMdd"
          ) + ", 23:59:59"
        : null;
      this.setDate(this.formEndDate, startDate);
    }
    // this.minStartDate = '2024-01-01';
    // this.maxStartDate = DateUtil.parseDate( this.endDate, 'yyyy-MM-dd');
    // this.minEndDate = '2024-01-01';
    // this.maxEndDate = '2024-01-01';
  }
  emitData() {
    this.checkValidDate();
    return {
      startDate: this.startDate,
      endDate: this.endDate,
    };
  }
  startDateNow() {
    document.getElementById("start-date").classList.toggle("active");
  }
  endDateNow() {
    document.getElementById("end-date").classList.toggle("active");
  }
}
