<div class="modal-content">
  <div class="modal-header">
  </div>
  <div class="modal-body">
    <div class="text-center">
      <div class="col-md-12 text-center">
        <img [src]="iconImage" class="icon-popup">
        <h4 class="popup-title">{{title}}</h4>
        <h4 class="popup-message">{{message}}</h4>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-12">
        <button class="btn btn-ok" (click)="dismiss()">{{'Đóng' | translate}}</button>
      </div>
    </div>
  </div>
</div>