<footer id="footer">
	<div class="container">
		<div class="row footer-bottom">
			<div class="col-12 text-center">
				<div class="btn-group dropup language float-left">
				</div>
				<p class="copyright small list-inline">© 2016 FINCORP JSC. ALL RIGHTS RESERVED</p>
			</div>
		</div>
	</div>
</footer>