import { Component, OnInit, Output, EventEmitter, Injector } from '@angular/core';
import { AppQueries } from 'src/app/state';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: [
        'footer.scss'
    ]
})
export class FooterComponent implements OnInit {
    theme: any = {};
    constructor(
        private appQueries: AppQueries,
    ) {
        
    }
    getYear() {
        let date = new Date();
        return date.getFullYear();
    }
    ngOnInit() {
        this.appQueries.theme$
        .subscribe((theme) => {
          this.theme = theme;
        });
    }
}
