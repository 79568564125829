export class DateUtil {
  static timeAgoParse(createTime) {
    createTime = new Date(parseInt(createTime)).getTime();
    const currentTime = Date.now();
  
    // It returns the time difference in Seconds...
    const timeDiffernce = (currentTime - createTime) / 1000;
  
    // To Calculate the time difference in Years...
    const years = 60 * 60 * 24 * 365;
  
    // To Calculate the time difference in Months...
    const  months = 60 * 60 * 24 * 30;
  
    // To Calculate the time difference in Days...
    const  days = 60 * 60 * 24;
  
    // To Calculate the time difference in Hours...
    const hours = 60 * 60;
  
    // To Calculate the time difference in Minutes...
    const  minutes = 60;
  
    if ((timeDiffernce / years) > 1) {
      return Math.floor(timeDiffernce / years) + ' years ago' ;
    } else if ((timeDiffernce / months) > 1) {
      return Math.floor(timeDiffernce / months) + ' months ago' ;
    } else if (((timeDiffernce / days)) > 1) {
      return Math.floor(timeDiffernce / days) + ' days ago' ;
    } else if (((timeDiffernce / hours)) > 1) {
      return Math.floor(timeDiffernce / hours) + ' hours ago' ;
    } else if (((timeDiffernce / minutes)) > 1) {
      return Math.floor(timeDiffernce / minutes) + ' minutes ago' ;
    } else if (((timeDiffernce)) > 1) {
      return Math.floor(timeDiffernce) + ' seconds ago' ;
    } else {
      return 'few seconds ago';
    }
  }
  static parseDate(date, formart) {
    date = new Date(date);
    const day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate();
    const month = date.getMonth() > 8 ? (date.getMonth() + 1) : '0' + (date.getMonth() + 1);
    const year = date.getYear() + 1900;
    const hour = date.getHours() > 9 ? date.getHours() : '0' + date.getHours();
    const minute = date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes();
    const second = date.getSeconds() > 9 ? date.getSeconds() : '0' + date.getSeconds();
    if (!formart) {
      return day + '/' + month + '/' + year;
    } else {
      const today = new Date();
      let result = formart;
      result = result.replace(/dd/g, day);
      result = result.replace(/MM/g, month);
      result = result.replace(/yyyy/g, year);
      result = result.replace(/hh/g, hour);
      result = result.replace(/mm/g, minute);
      result = result.replace(/ss/g, second);
      return result;
    }
  }
  static revertDate(str, formart) {
    if (!str) {
      return new Date();
    }
    const day = str.substring(formart.indexOf('dd'), formart.indexOf('dd') + 2);
    const month = str.substring(formart.indexOf('MM'), formart.indexOf('MM') + 2);
    const year = str.substring(formart.indexOf('yyyy'), formart.indexOf('yyyy') + 4);
    const hour = formart.indexOf('hh') > -1 ? str.substring(formart.indexOf('hh'), formart.indexOf('hh') + 2) : '00';
    const minute = formart.indexOf('mm') > -1 ? str.substring(formart.indexOf('mm'), formart.indexOf('mm') + 2) : '00';
    const second = formart.indexOf('ss') > -1 ? str.substring(formart.indexOf('ss'), formart.indexOf('ss') + 2) : '00';
    const date = `${month}/${day}/${year} ${hour}:${minute}:${second}`;
    return new Date(date);
  }
}
