// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  urlBackEnd : "https://apis.fplan.dev.techland.link/agency/",
  urlUploadBackEnd : "https://apis.fplan.dev.techland.link/agency/",
  capcha : "6LcbdE0UAAAAABxa9o8ACuOIwzcvw4Nx8GjCv952",
  asset : "https://s3-ap-southeast-1.amazonaws.com/fmarket-static/dev2",
  organization: "https://institution.fplan.dev.techland.link",
  partner: "https://fplan.dev.techland.link/auth/login",
};
