import { CopyProperties } from '../decorator/copy-properties.decorator';
@CopyProperties()
export class UserProfile {
    public id: number;
    public code: string;
    public name: string;
    public email: string;
    public passport: string;
    public passportDateOfIssue: string;
    public passportDateOfIssueModel: any;
    public passportPlaceOfIssue: any;
    public phone: string;
    public commissionRate: number;
    public bankBranch: string;
    public bankName: string;
    public bankNumber: string;
    public agencyPartnerCode: string;
    public partnerCode: string;
    constructor(
        params?: any
    ) {
        this.id = undefined;
        this.code = '';
        this.name = '';
        this.passport = '';
        this.passportDateOfIssue = '';
        this.passportPlaceOfIssue = '';
        this.phone = '';
        this.email = '';
        this.commissionRate = null;
        this.bankBranch = '';
        this.bankName = '';
        this.bankNumber = '';
        this.agencyPartnerCode = '';
        this.partnerCode = '';
    }
}